import tokens from "@/tokens";
import styled from "styled-components";

export const Flex = styled.div<{
  flexDirection?: string;
  gap?: number;
  alignItems?: string;
  paddingBottom?: number;
  paddingTop?: number;
  flexWrap?: string;
}>`
  display: flex;
  gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
  align-items: ${({ alignItems }) => alignItems && alignItems};
  position: relative;
  flex-direction: ${({ flexDirection }) => flexDirection && flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap && flexWrap};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : 0};
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : 0)};

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    flex-direction: column;
  }
`;

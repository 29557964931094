import styled from "styled-components";
import { SectionContainer } from "../SectionContainer";
import tokens from "@/tokens";

export const VideoSectionContainer = styled(SectionContainer)`
  min-height: 500px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #f6f9fc;
  flex-direction: column;
  max-width: unset;
  align-items: center;

  h2 {
    align-self: center;
    text-transform: capitalize;
    margin-bottom: 2rem;
  }

  h2,
  p,
  span,
  h3 {
    color: #000;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VideoPoster = styled.div`
  position: absolute;
  top: 50%;
  left: 60%;
  width: 350px;
  height: 350px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-image: url(assets/img/video-section/video-poster.webp);
  border-radius: 50%;
  background-position: center center !important;
  background-size: cover !important;
  transition: background-image 0.3s;
  cursor: pointer;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);

  :hover {
    ::before {
      background-color: unset;
    }
  }

  ::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s;
  }

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    left: 50%;
  }
`;

export const PlayIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const Circle = styled.circle`
  cx: 50;
  cy: 25;
  fill: transparent;
  r: 57;
  stroke: rgb(233, 161, 76);
  stroke-dasharray: 0.1, 4;
  stroke-width: 8px;
  animation: 50s linear infinite reverse rotateVideoFrame;
  -ms-transform-origin: center center;
  transform-origin: center center;

  @keyframes rotateVideoFrame {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CircleContainer = styled.svg`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: visible;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1340px;

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    flex-direction: column;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: #fff;
  overflow: auto;
  width: 80%;
  height: auto;
  max-height: 72vh;

  .header {
    display: flex;
    gap: 32px;
    border-bottom: 1px solid var(--tg-theme-primary);
    margin-bottom: 16px;

    h3 {
      color: #212529;
    }

    button {
      background: rgba(0, 0, 0, 0);
      width: 30px;
      border: none;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      padding-bottom: 6px;
      margin-left: auto;
      cursor: pointer;
      :hover {
        color: #345fdb;
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: auto;
    width: 100%;
    padding: 16px;
  }
`;

export const ResponsiveContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 37%;
  z-index: 10;

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    padding-top: 80.25%;
  }
`;

export const ResponsiveIframe = styled.iframe`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  .content {
    display: flex;
    flex-direction: column;
  }
`;

import React from "react";
import Image from "next/image";

import valorantImage from "public/assets/img/calculator/valorant.svg";
import wildRiftImage from "public/assets/img/calculator/wr.svg";
import lolImage from "public/assets/img/calculator/lol.svg";

import lolActive from "public/assets/img/calculator/lol-active.svg";
import wildRifActive from "public/assets/img/calculator/wr-active.svg";
import valorantActive from "public/assets/img/calculator/valorant-active.svg";

import wildRiftBase from "public/assets/img/services/wild-rift-base.webp";
import lolBase from "public/assets/img/services/lol-base.webp";
import valorantBase from "public/assets/img/services/valorant-base.webp";

import {
  GamesList,
  GameItem,
  Container,
  Service,
  ServicesContainer,
  ServicesAvailableContainer,
  HowWorks,
  Coluna,
  ServicesWrapper,
  ContainerFluid,
  Row,
} from "./styles";

import { CustomAcquireButton } from "../CustomAcquireButton";
import { PAGE_LABELS, LABELS } from "@/constants/general";
import { services } from "./contants";
import { FaArrowRight } from "react-icons/fa6";
import tokens from "@/tokens";
import Link from "next/link";

export const ServicesAvailable = () => {
  const [selectedgame, setSelectedGame] = React.useState(PAGE_LABELS.LOL);

  const isGameActive = (gameName: string) => String(gameName === selectedgame);

  const handleSelection = (game: string) => {
    setSelectedGame(game);
  };

  const fetchColor = {
    [PAGE_LABELS.LOL]: tokens.colors.league,
    [PAGE_LABELS.VALORANT]: tokens.colors.valorant,
    [PAGE_LABELS.WILD_RIFT]: tokens.colors.wildRift,
  };

  const handleDescription = (description: string) => {
    if (selectedgame === PAGE_LABELS.VALORANT) {
      return description.replace("Challenger", "Radiante");
    }
    return description;
  };

  return (
    <ServicesAvailableContainer>
      <GamesList>
        <ContainerFluid>
          <GameItem
            isactive={isGameActive(PAGE_LABELS.LOL)}
            onClick={() => handleSelection(PAGE_LABELS.LOL)}
            customColor={fetchColor[PAGE_LABELS.LOL]}
          >
            {isGameActive(PAGE_LABELS.LOL) != "true" ? (
              <Image
                src={lolImage}
                alt="ícone League of Legends"
                priority={true}
              />
            ) : (
              <Image
                src={lolActive}
                alt="ícone League of Legends"
                priority={true}
              />
            )}
          </GameItem>
          <GameItem
            isactive={isGameActive(PAGE_LABELS.WILD_RIFT)}
            onClick={() => handleSelection(PAGE_LABELS.WILD_RIFT)}
            customColor={fetchColor[PAGE_LABELS.WILD_RIFT]}
          >
            {isGameActive(PAGE_LABELS.WILD_RIFT) != "true" ? (
              <Image
                src={wildRiftImage}
                alt="ícone League of Legends"
                priority={true}
              />
            ) : (
              <Image
                src={wildRifActive}
                alt="ícone League of Legends"
                priority={true}
              />
            )}
          </GameItem>
          <GameItem
            isactive={isGameActive(PAGE_LABELS.VALORANT)}
            onClick={() => handleSelection(PAGE_LABELS.VALORANT)}
            customColor={fetchColor[PAGE_LABELS.VALORANT]}
          >
            {isGameActive(PAGE_LABELS.VALORANT) != "true" ? (
              <Image
                src={valorantImage}
                alt="ícone League of Legends"
                priority={true}
              />
            ) : (
              <Image
                src={valorantActive}
                alt="ícone League of Legends"
                priority={true}
              />
            )}
          </GameItem>
        </ContainerFluid>

        <HowWorks>
          <Container>
            <Row>
              <Coluna>
                <div className="title">
                  <hr className="bar" />
                  <h2>
                    {selectedgame.replace(/-/g, " ")} <br /> COMO{" "}
                    <span>FUNCIONA?</span>
                  </h2>
                </div>
                <ServicesWrapper>
                  {services.map((service, index) => (
                    <Service key={index}>
                      <span className="border-top"></span>
                      <span className="border-right"></span>
                      <span className="border-bottom"></span>
                      <span className="border-left"></span>

                      <div>
                        <div className="servicesIcon">
                          <service.icon
                            size={40}
                            color="var(--tg-theme-primary)"
                          />
                        </div>

                        <h4>
                          <Link href={`${service.link}/${selectedgame}`}>
                            {service.title}{" "}
                          </Link>
                        </h4>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: handleDescription(service.description),
                          }}
                        ></p>
                      </div>
                      <div className="buyNow">
                        <Link href={`${service.link}/${selectedgame}`}>
                          Comprar agora
                          <span>
                            <FaArrowRight />
                          </span>
                        </Link>
                      </div>
                    </Service>
                  ))}
                </ServicesWrapper>
              </Coluna>
              <Coluna>
                {selectedgame === PAGE_LABELS.WILD_RIFT && (
                  <Image
                    src={wildRiftBase}
                    alt="imagem sobre wild rift"
                    className="imageService"
                    width={924}
                    height={614}
                    loading="lazy"
                  />
                )}
                {selectedgame === PAGE_LABELS.VALORANT && (
                  <Image
                    src={valorantBase}
                    alt="imagem sobre valorant"
                    className="imageService"
                    width={924}
                    height={614}
                    loading="lazy"
                  />
                )}
                {selectedgame === PAGE_LABELS.LOL && (
                  <Image
                    src={lolBase}
                    alt="imagem sobre league of legends"
                    className="imageService"
                    width={924}
                    height={614}
                    loading="lazy"
                  />
                )}
              </Coluna>
            </Row>
          </Container>
        </HowWorks>
      </GamesList>
    </ServicesAvailableContainer>
  );
};

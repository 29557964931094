import styled from "styled-components";

export const ModContainer = styled.div<{ show: boolean }>`
  transition: opacity 500ms ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "inherit" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: contents;
  z-index: 200;

  > * {
    z-index: 800;
  }
`;

export const Overlay = styled.div<{ show: boolean }>`
  transition: opacity 500ms ease-in-out;
  opacity: ${({ show }) => (show ? 0.7 : 0)};
  pointer-events: ${({ show }) => (show ? "none" : "inherit")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
`;

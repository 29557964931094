import React from "react";

import { Modal } from "./styles";

import { ModalContainer } from "../ModalContainer";
import { CustomAcquireButton } from "../CustomAcquireButton";
import {
  coachingMessage,
  contactPhone,
  detailsMessage,
  md5Message,
  singleVictoriesMessage,
} from "@/constants/messaging";

type ModalProps = {
  show: boolean;
  handleClose: () => void;
  paymentCurrentStatus: string;
};

export const PaymentStatusModal: React.FC<ModalProps> = ({
  show,
  handleClose,
  paymentCurrentStatus,
}) => {
  const label = () => {
    if (paymentCurrentStatus === "approved") {
      return "Sucesso no pagamento, clique no botão abaixo e iniciaremos seu pedido imediatamente";
    }
    if (paymentCurrentStatus === "rejected") {
      return "Falha no pagamento, por favor, tente novamente, ou entre em contato com a Elojob Rise";
    }
    if (paymentCurrentStatus === "in_process") {
      return "O pagamento está pendente, caso tenha alguma dúvida, entre em contato com a Elojob Rise";
    }
  };

  const formatWhatsappMessage = (jobDetails: string) => {
    let object = JSON.parse(jobDetails);
    let message = "";
    const service = object.service;

    if (service === "md5") {
      message = md5Message(
        object.greeting,
        service,
        object.game,
        object.eloData,
        object.formattedPrice,
        object.quantityOfMatches,
        object.lessonType,
        object.toggles,
        object.primaryRole,
        object.secondaryRole
      );
    }  if (service === "vitorias") {
      message = singleVictoriesMessage(
        object.greeting,
        service,
        object.game,
        object.eloData,
        object.formattedPrice,
        object.quantityOfMatches,
        object.lessonType,
        object.toggles,
        object.primaryRole,
        object.secondaryRole
      );
    } 
    else if (service === "coach") {
      message = coachingMessage(
        object.greeting,
        service,
        object.game,
        object.formattedPrice,
        object.quantityOfMatches,
        object.lessonType,
        object.plan
      );
    } else {
      message = detailsMessage(
        object.greeting,
        object.service,
        object.game,
        object.eloData,
        object.formattedPrice,
        object.toggles,
        object.primaryRole,
        object.secondaryRole,
        object.currentPoints
      );
    }
    return message;
  };

  const handleReturnWhatsApp = () => {
    const jobDetails = localStorage.getItem("ELOJOB-RISE-JOB-DETAILS");
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let message = "";

    if (jobDetails) {
      let jobDetailsFormatted = formatWhatsappMessage(jobDetails);

      if (paymentCurrentStatus === "approved") {
        message = `\n Olá, fiz o pagamento via MERCADO PAGO na ELOJOB Rise, segue os dados do pedido para conferência:
        ${jobDetailsFormatted}
        `;
      }
      if (paymentCurrentStatus === "rejected") {
        message = `Meu pagamento via MERCADO PAGO na ELOJOB Rise falhou, por favor, poderia me com este problema? Segue os dados do pedido para conferência: ${jobDetailsFormatted}`;
      }
      if (paymentCurrentStatus === "in_process") {
        message = `O meu pagamento via MERCADO PAGO na ELOJOB Rise está pendente, por favor, poderia me com este problema? Segue os dados do pedido para conferência: ${jobDetailsFormatted}`;
      }

      message = encodeURIComponent(message);
    }

    if (isMobile) {
      const link = `whatsapp://send?phone=${contactPhone}&text=${message}`;
      window.open(link);
    } else {
      const link = `https://web.whatsapp.com/send?phone=${contactPhone}&text=${message}`;
      window.open(link, "_blank");
    }
  };

  return (
    <ModalContainer show={show}>
      <Modal>
        <div className="header">
          <h3>Confira o status do seu pagamento</h3>
          <button onClick={handleClose}>x</button>
        </div>
        <div className="body">
          <p>{label()}</p>
          <CustomAcquireButton onClick={handleReturnWhatsApp}>
            Entre em contato com a Elojob Rise
          </CustomAcquireButton>
        </div>
      </Modal>
    </ModalContainer>
  );
};

import styled from "styled-components";

import { SectionContainer } from "../SectionContainer";
import tokens from "@/tokens";
import { Flex } from "../Flex/styles";

export const CustomContainer = styled(SectionContainer)`
  text-align: center;
  position: relative;
  min-height: 100vh;
  align-items: center;
  width: 100%;
  z-index: 2;
  max-width: unset;
  flex-direction: column;
  max-width: unset;

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    height: 100%;
  }
`;

export const Video = styled.video`
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
`;

export const Elements = styled.div`
  max-width: 1440px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  z-index: 10;

  @media screen and (max-width: ${tokens.breakpoints.xl}) {
    max-width: 1024px;
  }

  @media screen and (max-width: ${tokens.breakpoints.l}) {
    max-width: 960px;
  }

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    max-width: 720px;
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    max-width: 100%;
    padding: 0;
  }

  a {
    span {
      color: #fff !important;

      :hover {
        color: #000 !important;
      }
    }
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    height: 100%;
  }

  @media screen and (max-height: ${tokens.breakpoints.s}) {
    margin-top: 130px;
  }

  svg {
    max-height: 100px;
    max-width: fit-content;
  }

  .main-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 7px;
    text-transform: uppercase;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    place-items: flex-start;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      flex-direction: column;
      gap: 0;
      text-align: center;
      align-items: center;
    }

    span {
      font-size: 2.2rem;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      color: #fff;

      @media screen and (max-width: ${tokens.breakpoints.s}) {
        font-size: 2rem;
      }

      @media screen and (max-height: ${tokens.breakpoints.s}) {
        font-size: 1.6rem;
      }
    }

    a {
      width: 120px;
    }

    .trademark {
      font-size: 2.2rem;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      color: var(--tg-theme-primary);

      @media screen and (max-width: ${tokens.breakpoints.s}) {
        font-size: 2rem;
      }

      @media screen and (max-height: ${tokens.breakpoints.s}) {
        font-size: 1.6rem;
      }
    }
  }

  .subtitle {
    font-size: 1.5vw;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      font-size: 5vw;
    }

    @media screen and (min-width: ${tokens.breakpoints.m}) {
      font-size: 1.5vw;
    }
  }
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 150px;

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    margin-top: 80px;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      height: 82vh;
      object-position: 64%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(54deg, rgb(0, 0, 0) 13%, rgba(0, 0, 0, 0) 61%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    &::after {
      background: linear-gradient(
        54deg,
        rgb(0, 0, 0) 20%,
        rgba(0, 0, 0, 0) 60%
      );
    }
  }
`;

export const Title = styled(Flex)`
  flex-direction: column;
  height: 50vh;
  justify-content: center;
  max-width: 70%;
  text-align: start;

  @media screen and (max-width: ${tokens.breakpoints.l}) {
    max-width: 80%;
    padding-left: 2rem;
  }

  .display-1,
  .display-1 span {
    font-size: 4rem;
    line-height: 1.15;
    font-weight: 900;
    font-style: normal;
    letter-spacing: 0px;
    text-transform: uppercase;

    span {
      color: var(--tg-theme-primary);
    }

    @media screen and (max-width: ${tokens.breakpoints.m}) {
      font-size: calc((4rem) / 1.6);
    }
    @media screen and (max-width: ${tokens.breakpoints.s}) {
      .display-1 {
        font-size: calc((4rem) / 1.8);
      }
    }
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    max-width: 100%;
    padding-left: 0;
  }

  h2 {
    font-size: 1.15rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 1.35;
    text-transform: uppercase;
    color: var(--tg-theme-primary);
  }

  .text {
    border-left: 2px solid currentColor;
    padding-left: 1rem;
    margin-left: 0.15em;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    max-width: 60%;

    @media screen and (max-width: ${tokens.breakpoints.m}) {
      max-width: 100%;
    }

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      text-align: center;
      border-left: 0;
      max-width: 100%;
    }
  }

  a {
    align-self: flex-start;
    font-family: "Poppins", sans-serif;
    span {
      color: #000;
    }

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      align-self: center;
    }
  }

  p {
    margin-top: 8px;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    color: #fff;

    @media screen and (max-height: ${tokens.breakpoints.s}) {
      font-size: 0.9rem;
    }
  }
`;

export const DicordBody = styled.div`
  position: absolute;
  right: 0;
  bottom: clamp(20%, 5vw, 25%);
  z-index: 99999;
  overflow: hidden;

  .dicordContent {
    border-radius: 0;
    padding: 20px 50px 20px 20px;
    margin-top: 1.25rem;
    font-weight: var(--grimlock-button-font-weight);
    text-transform: uppercase;
    margin-left: -10px;
    transform: translate3d(15px, 0, 0);
    font-size: 0.9rem;
    transition: all 0.2s ease-in-out;
    background-color: #6757d6;
    display: flex;
    color: #fff !important;
    align-items: center;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      padding: 10px;
    }

    &:hover {
      transform: translate3d(0, 0, 0);
    }

    svg {
      font-size: 3em;
      padding: 0px;
      margin-left: 0 !important;
      margin-right: 0.2em !important;
    }

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      margin-right: 5px !important;
    }

    p {
      margin-bottom: 0;
      text-align: start;
      line-height: 1.35;

      a {
        color: #fff !important;
        font-weight: 800;
        font-size: 12px;

        @media screen and (max-width: ${tokens.breakpoints.s}) {
          font-size: 0;

          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
          }
        }
      }
    }
  }
`;

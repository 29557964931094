import React from "react";
import Image from "next/image";

import {
  CustomContainer,
  Elements,
  BackgroundOverlay,
  Title,
  DicordBody,
} from "./styles";
import { CustomAcquireButton } from "../CustomAcquireButton";
import { IoLogoDiscord } from "react-icons/io5";
import Link from "next/link";

const backgroundImage = "/assets/img/landing-area/draven-gladiator.webp";

import { bluredBg } from "./constants";

export const MainArea = () => {
  return (
    <CustomContainer marginTop={0}>
      <BackgroundOverlay>
        {/* Using image, use this */}
        <Image
          src={backgroundImage}
          alt="draven"
          blurDataURL={bluredBg}
          width={1223}
          height={687}
          placeholder="blur"
          loading="eager"
        />
        {/* <video
          autoPlay
          muted
          playsInline
          loop
          poster={backgroundImage}
          controls={false}
        >
          <source src={backgroundVideoAnimated} type="video/mp4" />
        </video> */}
      </BackgroundOverlay>
      <Elements>
        <Title>
          <div className="main-title">
            <h2>Boost seu Elo</h2>
            <h3 className="display-1">
              com os melhores <br /> do
              <span> jogo!</span>
            </h3>
          </div>
          <div className="text">
            <p>
              Bem-vindo à Elojob Rise, uma empresa com mais de 6 anos de
              experiência e um histórico de mais de 10.000 serviços concluídos.
              Contamos com a expertise de uma equipe formada por profissionais
              dedicados e inclusive um ex-jogador profissional de League of
              Legends.
            </p>
          </div>

          <CustomAcquireButton link="/contratar/elojob/league-of-legends">
            Contratar
          </CustomAcquireButton>
        </Title>
      </Elements>

      <DicordBody>
        <div className="dicordContent">
          <IoLogoDiscord />
          <p>
            <Link href="https://discord.com/invite/B9YM7EAqCD" target="_blank">
              junte-se ao <br /> servidor do discord
            </Link>
          </p>
        </div>
      </DicordBody>
    </CustomContainer>
  );
};

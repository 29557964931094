import { useState } from "react";
import dynamic from "next/dynamic";

import CustomPage from "@/components/CustomPage";
import { ModalContainer } from "@/components/ModalContainer";
import {
  Modal,
  ResponsiveContainer,
  ResponsiveIframe,
} from "@/components/VideoSection/styles";

import { MainArea } from "@/components/MainArea";
import { ServicesAvailable } from "../components/ServicesAvailable";

const DynamicReadyToStartSection = dynamic(() =>
  import("../components/ReadyToStartSection").then(
    (mod) => mod.ReadyToStartSection
  )
);

const DynamicSectionFeedFaq = dynamic(() =>
  import("../components/SectionFeedFaq").then((mod) => mod.SectionFeedFaq)
);

const DynamicSectionDiscWhy = dynamic(() =>
  import("../components/SectionDiscWhy").then((mod) => mod.SectionDiscWhy)
);

const DynamicAboutSection = dynamic(() =>
  import("../components/AboutSection").then((mod) => mod.AboutSection)
);
const Details = dynamic(() =>
  import("../components/Details").then((mod) => mod.Details)
);

function Home() {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const hideVideo = () => {
    setShowVideoModal(false);
  };

  const showVideo = () => {
    setShowVideoModal(true);
  };

  return (
    <>
      <CustomPage
        canonicalUrl="https://elojobrise.com"
        title="ELOJOB RISE - ELOBOOST - ELOJOB - COACH - DUO BOOST"
      >
        <>
          <MainArea />
          <ServicesAvailable />
          <Details />
          <DynamicSectionDiscWhy />
          <DynamicReadyToStartSection />
          <DynamicSectionFeedFaq />
          <DynamicAboutSection />
        </>
      </CustomPage>
      <ModalContainer show={showVideoModal}>
        {showVideoModal && (
          <Modal>
            <div className="header">
              <h3>Conheça a Rise</h3>
              <button onClick={hideVideo}>x</button>
            </div>
            <ResponsiveContainer>
              <ResponsiveIframe
                allowFullScreen={true}
                title="Elojob Rise Tutorial"
                width="420"
                height="315"
                src="https://www.youtube.com/embed/wV18SN4CsFM"
              />
            </ResponsiveContainer>
          </Modal>
        )}
      </ModalContainer>
    </>
  );
}

export default Home;

import styled from "styled-components";

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: #181c23;
  overflow: auto;

  @media screen and (max-width: 600px) {
    height: 100%;
  }

  .header {
    display: flex;
    gap: 32px;
    border-bottom: 1px solid var(--tg-common-color-teal);
    margin-bottom: 16px;

    button {
      background: rgba(0, 0, 0, 0);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      padding-bottom: 6px;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;

    h3 {
      font-size: 25px;
    }
  }
`;



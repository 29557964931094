import { FaUser,FaUserFriends,FaTrophy,FaChalkboardTeacher   } from "react-icons/fa";

export const services = [
  {
    title: "Elojob",
    description: `<strong>É uma ótima escolha</strong> para quem quer agilidade e
    eficiência no serviço, um jogador de alto nível irá jogar em sua
    conta e garantir o <strong>elo solicitado!</strong>`,
    link: "/contratar/elojob",
    icon:FaUser
  },
  {
    title: "Duoboost",
    description: `Você quer o <strong>máximo de segurança</strong> para você e sua conta? Jogue duo com um Challenger e aprenda as <strong>melhores técnicas para subir de ELO!</strong>`,
    link: "/contratar/duoboost",
    icon:FaUserFriends
  },
  {
    title: "MD5",
    description: `Iremos <strong>garantir 80% de vitorias na sua MD5</strong> os melhores Boosters são selecionados para fazer esse tipo de serviço e <strong>garantir as vitórias!</strong>`,
    link: "/contratar/md5",
    icon:FaTrophy
  },
  {
    title: "Coach",
    description: `<strong>Melhore</strong> sua gameplay através de <strong>aulas</strong> práticas com <strong>professores</strong>.`,
    link: "/contratar/coach",
    icon:FaChalkboardTeacher
  },
];

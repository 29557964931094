import React from "react";
import { Container } from "./styles";

type ContentContainerProps = {
  children: any;
  className?: any;
  flexDirection?: string;
  marginTop?: number;
  id?: string;
  minHeight?: string;
  style?: any;
};

export const SectionContainer: React.FC<ContentContainerProps> = ({
  children,
  className,
  marginTop,
  flexDirection,
  id,
  minHeight,
  style
}) => {
  return (
    <Container
      marginTop={marginTop}
      minHeight={minHeight}
      className={className}
      id={id}
      flexDirection={flexDirection}
      style={style}
    >
      {children}
    </Container>
  );
};

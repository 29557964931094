import tokens from "@/tokens";
import styled, { css } from "styled-components";
import { SectionContainer } from "../SectionContainer";


export const GamesList = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 10;
  justify-content: center;
  padding: 0px;
  align-items: center;
  margin-top: -160px;
 
  width: 100%;

 
`;

export const Service = styled.div<{ customColor?: string }>`
  background: #181b22;
  height:100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  border-radius:8px;
  height: auto;
  border-radius:8px;
  position:relative;
  overflow:hidden;  
 
  
  span.border-top{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:3px;
    background:linear-gradient(to right,transparent,var(--tg-theme-primary));
    animation:boderTopAnimation 2s linear infinite;
  
  }

  
  @keyframes boderTopAnimation{
    0%{
      transform:translateX(-100%);
    }
    100%{
      transform:translateX(100%);
    }

  }

  span.border-right{
    position:absolute;
    top:0;
    right:0;
    width:3px;
    height:100%;
    background:linear-gradient(to bottom,transparent,var(--tg-theme-primary));
    animation:boderRightAnimation 2s linear infinite;
    animation-delay:1s;

  }

  @keyframes boderRightAnimation{
    0%{
      transform:translateY(-100%);
    }
    100%{
      transform:translateY(100%);
    }

  }

  span.border-bottom{
    position:absolute;
    bottom:0;
    right:0;
    width:100%;
    height:3px;
    background:linear-gradient(to left,transparent,var(--tg-theme-primary));
    animation:boderBottomAnimation 2s linear infinite;
  }

  @keyframes boderBottomAnimation{
    0%{
      transform:translateX(100%);
    }
    100%{
      transform:translateX(-100%);
    }

  }

  span.border-left{
    position:absolute;
    top:0;
    left:0;
    width:3px;
    height:100%;
    background:linear-gradient(to top,transparent,var(--tg-theme-primary));
    animation:boderLeftAnimation 2s linear infinite;
    animation-delay:1s;
  }
  
  
  @keyframes boderLeftAnimation{
    0%{
      transform:translateY(100%);
    }
    100%{
      transform:translateY(-100%);
    }

  }

 

  .title {
    font-family: var(--tg-heading-font-family-old);
    color: ${({ customColor }) =>
      customColor ? customColor : "var(--tg-theme-primary)"};
    border-color: ${({ customColor }) =>
      customColor ? customColor : "var(--tg-theme-primary)"};
    font-size: 22px;
    background: -webkit-linear-gradient(#ffc60b, #ff872c);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-family: var(--tg-heading-font-family-old);
    margin-bottom: 0;

    strong {
      color: #fff;
      font-weight: bolder;
    }
  }

  h4{
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 1.2px;
    margin: 0 0 18px;
  

    a{
      box-shadow: none;
      text-decoration: none;
      color:#fff ;
 
      &:hover{
        color:var(--tg-theme-primary);
      }
    }
  }

  p{
    color:#adb0bc;

    strong{
      color:#adb0bc;
    }
  }
  
  .servicesIcon{
    margin: 0 0 10px;
  }

  .buyNow{
    font-weight: 700;

    a{
      display:flex;
      align-items:center;
      gap:10px;

      &:hover{
        transform:translateY(-5px);
      }

      span{
        color:var(--tg-theme-primary);
        display:flex;
        opacity:0;
        animation:anima-seta 1000ms ease infinite;
    
         
      }
    }

  }


  @keyframes anima-seta {
    to {
        opacity:1;
        transform: translateX(18px);
    }
  }
`;

export const ContainerFluid = styled.div`
  background: linear-gradient(54deg,rgba(25,29,38) ,rgba(25,29,38,0.5) ,rgba(25,29,38)  );
  max-width: 100%;
  width:100%;
  height: 130px;
  filter: drop-shadow(0 0 100px rgba(0, 0, 0, 0.85));
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  gap:20px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  @media screen and (max-width: ${tokens.breakpoints.m}) {
      flex-direction:column;
  }
`;

export const Container = styled.div`
    max-width: 1440px;
    margin:auto;
    padding-right: 15px;
    padding-left: 15px;

    @media screen and (max-width: ${tokens.breakpoints.xl}) {
      max-width:1024px;
    }
    
    @media screen and (max-width: ${tokens.breakpoints.l}) {
      max-width: 960px;
    }

    @media screen and (max-width: ${tokens.breakpoints.m}) {
      max-width: 720px;
    }

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      max-width: 100%;
    }

`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 150px;
  gap: 32px;
  // max-width: 1140px;
  width:100%;
  padding: 16px;
  margin-bottom: 16px; 
   background:rgba(25,29,38);
 


  @media screen and (max-width: ${tokens.breakpoints.s}) {
    flex-direction: column;
    padding: 16px;
  }
`;

export const GameItem = styled.div<{
  isactive: string;
  isdisabled?: string;
  customColor?: string;
}>`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  border-style: double;
  border-radius: 5px;
  padding: 16px;
  position: relative;
  text-align: center;
  cursor: pointer;

  
  :hover {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1),
      rgba(138, 204, 255, 0.3),
      rgba(255, 255, 255, 0.1)
    );
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    display: flex;
    width: 100%;
    min-width: unset;

    > * {
      flex: 1;
    }
  }

  :hover {
    border-color: ${({ customColor }) =>
      customColor ? customColor : "var(--tg-theme-primary)"};
  }



  span {
    font-size: 20px;
    font-weight: bold;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      font-size: 12px;
    }
  }

  :nth-child(1) {
    span {
      color: rgb(245, 171, 78);
    }
  }

  :nth-child(2) {
    span {
      color: rgb(245, 171, 78);
    }
  }

  :nth-child(3) {
    span {
      color: rgb(245, 171, 78);
    }
  }

  img {
    width: 80px;
    height: auto !important;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      width: 60px;
    }
  }
  
`;

export const ServicesAvailableContainer = styled(SectionContainer)`
  max-width: unset;
  padding: 0;
`;


export const HowWorks = styled.div`
  background-color: #1b1f28;
  width:100%;
  background-image: url('assets/img/bg/bg3home.webp');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size:cover;
  padding: 65px 0;

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    background-position: center center;
    background-size:auto;
    background-repeat: repeat;
  }

`

export const Coluna = styled.div`
  position: relative;
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  display:flex;
  flex-direction:column;
  justify-content:center;

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    max-width: 100%;
  }


  .title{
    position:relative;
    margin-top: clamp(25px, 3vw, 40px);


    hr.bar{
      width:100%;
      max-width:100%;
      height:10px;
      background:transparent;
      border:none;
      color:#fff !important;
      opacity:1;
      margin-left: 0;
      position:relative;
     
  
     &::before{
        content: '';
        height: 7px;
        width: 75px;
        background-color: currentColor;
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: scaleX(-1);
        -webkit-clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 100%, 100% 100%, 0 100%);
        clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 100%, 100% 100%, 0 100%);
      }
    
       &::after{
        content: '';
        display: block;
        height: 1px;
        background-color: currentColor;
        position: absolute;
        bottom:0;
        left: 0;
        right: 0;
      }
    
    }

    
    h2 ,h2 span{
      margin-top: clamp(25px, 3vw, 40px);
      font-weight:900;
      letter-spacing: 0px;
      text-transform: uppercase;
      font-size: clamp(calc( (3rem) / 1.5), 5vw, 3rem);

       span {
        color: var(--tg-theme-primary);
       }

     
    }
    
  }

  .imageService{
    mask-image: url(assets/img/services/maskara.svg);
    mask-position: center; 
    mask-repeat: no-repeat;
    mask-size: contain;
    object-fit: cover;
    max-width:100%;
    width:auto;
    object-fit: contain;
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    h2{
      text-align:center ;
    }
 }
`
 

export const ServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top:50px;
  margin-bottom:50px;

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    grid-template-columns: repeat(1, 1fr);
    text-align:center;
    margin-bottom:0;

  }

`
import preencherRole from "/public/assets/img/roles/preencher.png";
import topRole from "/public/assets/img/roles/top.png";
import botRole from "/public/assets/img/roles/bot.png";
import midRole from "/public/assets/img/roles/mid.png";
import supRole from "/public/assets/img/roles/suporte.png";
import jungleRole from "/public/assets/img/roles/jungle.png";
import { StaticImageData } from "next/image";

export const LABELS = {
  LOL: "League of Legends",
  WILD_RIFT: "Wild Rift",
  VALORANT: "Valorant",
};

export const PAGE_LABELS = {
  LOL: "league-of-legends",
  WILD_RIFT: "wild-rift",
  VALORANT: "valorant",
};

 

export const roles = [
  {
    label: "Selecione",
    value: "fit",
    image: preencherRole,
  },
  {
    label: "Top",
    value: "top",
    image: topRole,
  },
  {
    label: "Jungle",
    value: "jungle",
    image: jungleRole,
  },
  {
    label: "Mid",
    value: "mid",
    image: midRole,
  },
  {
    label: "Bot",
    value: "bot",
    image: botRole,
  },
  {
    label: "Suporte",
    value: "support",
    image: supRole,
  },
  {
    label: "Preencher",
    value: "fit",
    image: preencherRole,
  },
];

export type EloType = {
  displayName: string;
  image: StaticImageData;
  name: string;
};

export const points = [
  {
    label: "0-20",
    price: 0,
  },
  {
    label: "21-40",
    price: 1,
  },
  {
    label: "41-60",
    price: 2,
  },
  {
    label: "61-80",
    price: 3,
  },
  {
    label: "80-99",
    price: 4,
  },
];

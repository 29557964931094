import Head from "next/head";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { PaymentStatusModal } from "../PaymentStatusModal";
import { UserContext } from "@/contexts/userContext";

type CustomPageProps = {
  children: React.ReactElement;
  canonicalUrl: string;
  title: string;
  isPrivate?: boolean;
};

const CustomPage: React.FC<CustomPageProps> = ({
  children,
  canonicalUrl,
  title,
  isPrivate,
}) => {
  const router = useRouter();

  const { isLoggedIn } = useContext(UserContext);

  useEffect(() => {
    if (!isLoggedIn && isPrivate) {
      router.push("/login");
    }
  }, [isPrivate, router, isLoggedIn]);

  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const [paymentCurrentStatus, setPaymentCurrentStatus] = useState("");

  const hidePaymentStatusModal = () => {
    setShowPaymentStatusModal(false);
  };

  useEffect(() => {
    const paymentStatus = router.query.status as string;

    if (paymentStatus && paymentStatus !== "" && paymentStatus !== "null") {
      setPaymentCurrentStatus(paymentStatus);
      setShowPaymentStatusModal(true);
      router.replace(router.pathname, undefined, { shallow: true });
    }
  }, [router, router.query.status]);

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={title} />
        <title>{title}</title>
      </Head>
      <>{children}</>

      <PaymentStatusModal
        show={showPaymentStatusModal}
        handleClose={hidePaymentStatusModal}
        paymentCurrentStatus={paymentCurrentStatus}
      />
    </>
  );
};

export default CustomPage;

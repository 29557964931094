import tokens from "@/tokens";
import styled from "styled-components";

export const Container = styled.section<{
  marginTop?: number;
  flexDirection?: string;
  minHeight?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection && flexDirection};
  max-width: 1280px;
  align-self: center;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
  padding: 16px;
  z-index: 2;
  min-height: ${({ minHeight }) => minHeight && minHeight}; 
`;

import React from "react";
import { ModContainer, Overlay } from "./styles";
import useScreenSize from "@/hooks/useScreenSize";

export const ModalContainer = ({
  children,
  show,
}: {
  children: any;
  show: boolean;
}) => {
  const { isMobile } = useScreenSize();

  React.useEffect(() => {
    const headerElement = document.getElementById("header-id");
    const footerElement = document.getElementById("footer-id");
    const body = document.getElementsByTagName("body");

    if (show) {
      if (headerElement && footerElement && body) {
        headerElement.style.zIndex = "1";
        footerElement.style.zIndex = "1";
        body[0].style.overflowY = "hidden";
      }
    } else {
      if (headerElement && footerElement) {
        headerElement.style.zIndex = "9";
        footerElement.style.zIndex = "9";
        body[0].style.overflowY = "auto";
      }
    }
  }, [isMobile, show]);

  return (
    <ModContainer show={show} id={show ? "modal-open" : "modal-closed"}>
      <Overlay show={show} />
      {children}
    </ModContainer>
  );
};
